import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import dataModlue from '../../modules/data'
import { breakpoint } from '../../utils'

const Popup = (props) => {
  const dispatch = useDispatch()
  const appData = useSelector((state) => state.data)

  const { content, showPopup } = appData

  const handleClickClose = () => {
    dispatch(
      dataModlue.set({
        key: 'showPopup',
        value: false
      })
    )
  }

  const handleClickLink = () => {
    handleClickClose(false)
    window.open(content.popup_link)
  }

  if (isEmpty(appData.content)) return null
  if (appData.content.show_popup === 'no') return null
  if (!showPopup) return null

  return (
    <Style>
      <div className='overlay' onClick={handleClickClose}></div>
      <div className='close-popup' onClick={handleClickClose}>
        <i className='fas fa-times'></i>
      </div>
      <img src={content.popup_image} alt='' className='popup-image' onClick={handleClickLink} />
      <img
        src={content.popup_image_mobile}
        alt=''
        className='popup-image mobile'
        onClick={handleClickLink}
      />
    </Style>
  )
}

Popup.propTypes = {
  facebookURL: PropTypes.string.isRequired,
  lineURL: PropTypes.string.isRequired
}

export default Popup

const Style = styled.div`
  label: Popup;

  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .close-popup {
    position: absolute;
    z-index: 2;
    font-size: 20px;
    color: #fff;
    top: 0;
    right: 0;
    padding: 10px 15px;
    cursor: pointer;
  }

  .overlay {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0 / 80%);
  }

  .popup-image {
    position: relative;
    z-index: 1;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    cursor: pointer;

    &.mobile {
      display: none;
    }
  }

  ${breakpoint('500px')} {
    .popup-image {
      display: none;

      &.mobile {
        display: block;
      }
    }
  }
`
