import React from 'react'
import { hydrate, render } from 'react-dom'
import * as serviceWorker from './serviceWorker'
import './utils/smoothScroll'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import App from './App'

gsap.registerPlugin(ScrollTrigger)

new window.GambitSmoothScroll({
  amount: 250, // The scroll amount
  speed: 1500 // The scroll speed
})

const rootElement = document.getElementById('root')

if (rootElement === null) {
  throw new Error('No root element')
}

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement)
} else {
  render(<App />, rootElement)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
