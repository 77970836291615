import Styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import BannerPage from '../components/BannerPage'
import Breadcrumb from '../components/Breadcrumb'
import PageContainer from '../components/PageContainer'
import BlogContentStyle from '../components/BlogContentStyle'

const Policy = () => {
  const appData = useSelector((state) => state.data)

  if (isEmpty(appData.content)) return null

  const { content } = appData

  return (
    <>
      <Helmet>
        <title>{content.meta_title_policy}</title>
        <meta name='title' content={content.meta_title_policy} />
        <meta name='description' content={content.meta_description_policy} />

        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://www.onmywatch-shop.com/policy' />
        <meta property='og:title' content={content.meta_title_policy} />
        <meta property='og:description' content={content.meta_description_policy} />
        <meta property='og:image' content='https://www.onmywatch-shop.com/on-my-watch.png' />
        <meta property='og:image:type' content='image/*' />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://www.onmywatch-shop.com/policy' />
        <meta property='twitter:title' content={content.meta_title_policy} />
        <meta property='twitter:description' content={content.meta_description_policy} />
        <meta
          property='twitter:image'
          content='https://www.onmywatch-shop.com/on-my-watch.png'
        ></meta>
      </Helmet>
      <BannerPage
        image={content.top_banner_policy}
        name='Policy'
        title={content.banner_title_policy}
      />

      <PageContainer>
        <Style>
          <Breadcrumb items={[{ label: 'นโยบาย' }]} />
          <BlogContentStyle content={content.content_policy} />
        </Style>
      </PageContainer>
    </>
  )
}

const Style = Styled('div')`
  label: Policy;
`

export default Policy
