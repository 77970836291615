import { Route, Switch, useLocation } from 'react-router-dom'
import React from 'react'
import { useTransition, animated, config } from 'react-spring'

import Home from './pages/Home/index.js'
import AboutUs from './pages/AboutUs'
import Product from './pages/Product'
import ProductDetail from './pages/ProductDetail'
import BuyWatches from './pages/BuyWatches'
import Warranty from './pages/Warranty'
import Blog from './pages/Blog'
import BlogDetail from './pages/BlogDetail'
import ContactUs from './pages/ContactUs'
import Policy from './pages/Policy'
import PageNotFound from './pages/PageNotFound'

const Routes = () => {
  const location = useLocation()
  const transitions = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 100 }
  })

  return transitions.map(({ item: location, props, key }) => (
    <animated.div key={key} style={props}>
      <Switch location={location}>
        <Route exact path='/' component={Home} />
        <Route exact path='/about-us' component={AboutUs} />
        <Route exact path='/products' component={Product} />
        <Route exact path='/products/:id' component={ProductDetail} />
        <Route exact path='/product-category/:categorySlug' component={Product} />
        <Route exact path='/รับซื้อนาฬิกา' component={BuyWatches} />
        <Route exact path='/warranty' component={Warranty} />
        <Route exact path='/blog' component={Blog} />
        <Route exact path='/blog/:id' component={BlogDetail} />
        <Route exact path='/tag/:postType/:tagId/:tagSlug' component={Blog} />
        <Route exact path='/contact-us' component={ContactUs} />
        <Route exact path='/policy' component={Policy} />
        <Route component={PageNotFound} />
      </Switch>
    </animated.div>
  ))
}

export default Routes
