import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

const LoadMore = (props) => {
  return <Style onClick={props.onClick}>Load More</Style>
}

LoadMore.propTypes = {
  onClick: PropTypes.func.isRequired
}

LoadMore.defaultProps = {
  onClick: () => null
}

export default LoadMore

const Style = styled.div`
  label: LoadMore;

  background: #171717;
  color: #fff;
  padding: 15px 60px;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
`
