import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { gsap } from 'gsap'

import { breakpoint } from '../../utils'

const PageContainer = (props) => {
  const ref = useRef()

  useEffect(() => {
    const element = ref.current
    gsap.fromTo(
      element.querySelector('.box-body'),
      {
        opacity: 0,
        y: 20
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        delay: 1
      }
    )
  }, [])

  return (
    <Style bgColor={props.bgColor} ref={ref} noBanner={props.noBanner}>
      <div className='box-body'>{props.children}</div>
    </Style>
  )
}

export default PageContainer

const Style = styled.div`
  label: PageContainer;

  background: ${(props) => (props.bgColor ? props.bgColor : '#fff')};
  padding: 60px 20px;

  border-top: ${(props) => (props.noBanner ? '100px solid #000' : 'none')};

  > .box-body {
    width: 100%;
    max-width: 870px;
    margin: 0 auto;
    color: #000;
  }

  ${breakpoint('T')} {
    border-top: ${(props) => (props.noBanner ? '70px solid #000' : 'none')};
  }

  ${breakpoint('M')} {
    padding: 20px 20px 40px;
  }
`
