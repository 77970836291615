import { useRef, useEffect, useState } from 'react'
import Styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import qs from 'qs'
import Toastify from 'glud-component/lib/Toastify'
import { gsap } from 'gsap'

import TitleSection from '../components/TitleSection'
import Breadcrumb from '../components/Breadcrumb'
import PageContainer from '../components/PageContainer'
import mapImage from '../images/location-map.jpg'
import pin from '../images/pin.svg'
import { breakpoint } from '../utils'
import InputField from '../components/InputField'

const initialForm = {
  fullname: '',
  email: '',
  phone: '',
  message: ''
}

const ContactUs = () => {
  const ref = useRef()

  useEffect(() => {
    const element = ref.current

    gsap.fromTo(
      element,
      {
        opacity: 0,
        y: -20
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        delay: 0.5
      }
    )
  }, [])

  const [form, setForm] = useState(initialForm)

  const [saving, setSaving] = useState(false)
  const appData = useSelector((state) => state.data)

  if (isEmpty(appData.content)) return null
  const { content } = appData

  const handleInputChange = (key, value) => {
    setForm({
      ...form,
      [key]: value
    })
  }

  const emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i

  const isFormError = () => {
    if (form.fullname === '') return true
    if (!emailCheck.test(form.email)) return true
    if (form.phone === '') return true
    if (form.message === '') return true
    return false
  }

  const handleClickSubmit = async () => {
    if (isFormError()) {
      setSaving(false)
      return
    }
    const dataRegister = {
      action: 'send_message',
      fullname: form.fullname,
      email: form.email,
      phone: form.phone,
      message: form.message,
      mailTo: content.email
    }

    const loading = Toastify({
      type: 'LOADING',
      message: 'Sending..'
    })

    try {
      setSaving(true)
      const res = await axios({
        method: 'POST',
        url: 'https://www.content.onmywatch-shop.com/wp-admin/admin-ajax.php',
        data: qs.stringify(dataRegister)
      })

      if (res.data.type === 'success') {
        setForm(initialForm)
      }

      loading.close()
      setSaving(false)
      Toastify({
        type: 'SUCCESS',
        message: 'Send message successful.',
        closeTime: 3
      })
    } catch (err) {
      loading.close()
      setSaving(false)
      Toastify({
        type: 'ERROR',
        message: 'Send message failed.',
        closeTime: 5
      })
      console.log('errr', err)
    }
  }

  return (
    <>
      <Helmet>
        <title>{content.meta_title_contact_us}</title>
        <meta name='title' content={content.meta_title_contact_us} />
        <meta name='description' content={content.meta_description_contact_us} />

        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://www.onmywatch-shop.com/contact-us' />
        <meta property='og:title' content={content.meta_title_contact_us} />
        <meta property='og:description' content={content.meta_description_contact_us} />
        <meta property='og:image' content='https://www.onmywatch-shop.com/on-my-watch.png' />
        <meta property='og:image:type' content='image/*' />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://www.onmywatch-shop.com/contact-us' />
        <meta property='twitter:title' content={content.meta_title_contact_us} />
        <meta property='twitter:description' content={content.meta_description_contact_us} />
        <meta
          property='twitter:image'
          content='https://www.onmywatch-shop.com/on-my-watch.png'
        ></meta>
      </Helmet>
      <MapStyle>
        <img
          ref={ref}
          src={pin}
          alt=''
          className='pin'
          onClick={() => window.open(content.google_map_link)}
        />
      </MapStyle>
      <PageContainer>
        <Style>
          <Breadcrumb items={[{ label: 'ติดต่อเรา' }]} />
          <div className='row columns-between'>
            <div className='D-4 T-6 SM-12'>
              <TitleSection label='Contact Us' title='On my watch' />
              <br />
              <div className='contact-info'>
                <h3>Address</h3>
                <p>{content.address}</p>
                <br />
                <h3>Email</h3>
                <p>
                  <a href={`mailto:${content.email}`} className='tel'>
                    {content.email}
                  </a>
                </p>
                <br />
                <h3>Phone number</h3>
                <p>
                  <a href={`tel:${content.phone_number}`} className='tel'>
                    {content.phone_number}
                  </a>
                </p>
                <br />
                <h3>Line</h3>
                <p>
                  <a href={`${content.line_id}`} className='tel'>
                    @Onmywatch
                  </a>
                </p>
                <br />
                <h3>Social</h3>
                <div className='social-link'>
                  <a href={content.facebook} target='_blank' className='fab fa-facebook-f'></a>
                  <a href={content.instagram} target='_blank' className='fab fa-instagram'></a>
                  <a href={content.twitter} target='_blank' className='fab fa-twitter'></a>
                  <a href={content.youtube} target='_blank' className='fab fa-youtube'></a>
                </div>
                <br />
              </div>
            </div>
            <div className='D-6 T-6 SM-12'>
              <TitleSection label='.' title='Get in touch' />
              <br />
              <InputField
                placeholder='Fullname'
                value={form.fullname}
                onChange={(e) => handleInputChange('fullname', e.target.value)}
                isError={form.fullname === ''}
                errorMessage='Please enter fullname.'
              />
              <br />
              <InputField
                placeholder='Email'
                value={form.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                isError={!emailCheck.test(form.email)}
                errorMessage='Please enter email.'
              />

              <br />
              <InputField
                placeholder='Phone'
                value={form.phone}
                onChange={(e) => handleInputChange('phone', e.target.value)}
                isError={form.phone === ''}
                errorMessage='Please enter phone.'
              />
              <br />
              <InputField
                placeholder='Message'
                value={form.message}
                onChange={(e) => handleInputChange('message', e.target.value)}
                isError={form.message === ''}
                errorMessage='Please enter message.'
              />
              <br />
              <div className='_right'>
                <button
                  className='button-submit'
                  disabled={saving}
                  type='submit'
                  onClick={handleClickSubmit}
                >
                  {saving ? 'SENDING...' : 'SEND MESSAGE'}
                </button>
              </div>
            </div>
          </div>
        </Style>
      </PageContainer>
    </>
  )
}
const MapStyle = Styled('div')`
  position: relative;
  background: #000 url(${mapImage}) center center no-repeat;
  background-size: cover;
  height: 500px;
  border-top: 100px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoint('T')} {
    border-top: 70px solid #000;
  }

  .pin {
    width: 60px;
    cursor: pointer;
  }
`
const Style = Styled('div')`
  label: ContactUs;

  .input {
    background: #f8f8f8;
  }

  .button-submit {
    background: #000;
    color: #fff;
    padding: 10px 30px;
    display: inline-block;
    cursor: pointer;
    font-size: 10px;
    border: none;
    outline: none;
  }

  .contact-info {
    font-family: 'SukhumvitSet';

    h3 {
      font-family: 'Geometos';
      margin-bottom: 5px;
    }

    .tel {
      color: #000;
      text-decoration: none;
    }

    .social-link {
      a {
        color: #000;
        transition: all 0.3s ease-in-out;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          color: #a98e55;
        }
      }
    }
  }

  ${breakpoint('M')} {
    .button-submit {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
`

export default ContactUs
