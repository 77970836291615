import Styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { FacebookShareButton, FacebookIcon } from 'react-share'

import BannerPage from '../components/BannerPage'
import PageContainer from '../components/PageContainer'
import Breadcrumb from '../components/Breadcrumb'
import BlogItem from '../components/BlogItem'
import bannerImage from '../images/blog-banner.jpg'
import { history } from '../utils'
import { Helmet } from 'react-helmet'
import BlogContentStyle from '../components/BlogContentStyle'

const BlogDetail = () => {
  const appData = useSelector((state) => state.data)
  const { content, blogs, tags } = appData

  const { id } = useParams()

  const blog = blogs.find((item) => Number(item.id) === Number(id))

  if (!blog) return null

  const getTagData = (tagId, key) => {
    const result = tags.find((t) => t.id === tagId)
    if (result) return result[key]
    return null
  }

  const handleCLickTag = (tag) => {
    history.push(`/tag/blog/${tag}/${getTagData(tag, 'slug')}`)
  }

  const moreBlogs = blogs.filter((item, i) => Number(item.id) !== Number(id))

  return (
    <>
      <Helmet>
        <title>{blog.title.rendered} - On My Watch</title>
        <meta name='title' content={blog.title.rendered} />
        <meta name='description' content={blog.acf.short_description} />
        <meta property='image' content={blog.acf.featured_image} />

        <meta property='og:type' content='website' />
        <meta property='og:url' content={`https://www.onmywatch-shop.com/blog/${id}`} />
        <meta property='og:title' content={blog.title.rendered} />
        <meta property='og:description' content={blog.acf.short_description} />
        <meta property='og:image:type' content='image/*' />
        <meta property='og:image' content={blog.acf.featured_image} />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content={`https://www.onmywatch-shop.com/blog/${id}`} />
        <meta property='twitter:title' content={blog.title.rendered} />
        <meta property='twitter:description' content={blog.acf.short_description} />
        <meta property='twitter:image' content={blog.acf.featured_image}></meta>
      </Helmet>
      <BannerPage image={bannerImage} name='From the blog' title={content.banner_title_blog} />
      <PageContainer>
        <Style>
          <Breadcrumb items={[{ label: 'บทความ', to: '/blog' }]} />
          <div className='blog-title'>{blog.title.rendered}</div>
          <div className='blog-date'>{moment(blog.date).format('DD MMMM YYYY')}</div>
          <div className='blog-image'>
            <img src={blog.acf.featured_image} alt='' />
          </div>
          <BlogContentStyle content={blog.acf.content_blog} />
          <br />
          <div className='box-tags'>
            <b>Tags:</b>
            {blog.tags.map((tag) => (
              <span className='tag-item' onClick={() => handleCLickTag(tag)}>
                {getTagData(tag, 'name')}
              </span>
            ))}
          </div>
          <div className='box-share'>
            <b>Share:</b>
            <FacebookShareButton
              url={`https://www.onmywatch-shop.com/blog/${id}`}
              quote={blog.title.rendered}
            >
              <FacebookIcon size={36} />
            </FacebookShareButton>
          </div>
          <br />
          <br />
          <br />
          <div className='title-more'>
            <h3>Other articles :</h3>
          </div>
          <div className='row'>
            {moreBlogs.map((item, i) => {
              if (i > 1) return null
              return (
                <div className='D-6 SM-12'>
                  <BlogItem
                    title={item.title.rendered}
                    desc={item.acf.short_description}
                    date={item.date}
                    image={item.acf.featured_image}
                    onClick={() => history.push(`/blog/${item.id}/?title=${item.title.rendered}`)}
                  />
                </div>
              )
            })}
          </div>
        </Style>
      </PageContainer>
    </>
  )
}

const Style = Styled('div')`
  label: BlogDetail;

  .blog-title {
    font-family: 'SukhumvitSet';
    font-weight: bold;
    font-size: 18px;
  }

  .blog-date {
    font-family: 'SukhumvitSet';
    margin: 10px 0 40px;
    font-size: 10px;
    letter-spacing: 3.6px;
    color: #888888;
  }

  .box-share {
    margin-top: 20px;
    display: flex;
    align-items: center;
    b {
      margin-right: 10px;
    }
  }
  
  .title-more {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px;
    
    h3 {
      display: inline-block;
      background: #fff;
      padding-right: 10px;
      margin-bottom: 0;
      bottom: -8px;
      position: relative;
    }
  }

  .tag-item {
    font-family: 'SukhumvitSet';
    padding: 5px 10px;
    display: inline-block;
    cursor: pointer;
    background: #e5e5e5;
    margin-left: 10px;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 10px;
  }
`

export default BlogDetail
