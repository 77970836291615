import Styled from '@emotion/styled'

import image from '../images/welcome.jpg'
import { fluidSizing } from '../utils'

const PageNotFound = () => {
  return (
    <Style>
      <div>404 Page not found</div>
    </Style>
  )
}

const Style = Styled('div')`
  label: PageNotFound;

  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  background: #000 url(${image}) bottom center no-repeat;
  background-size: cover;
  
  ${fluidSizing('font-size', { SM: 20, M: 20, T: 60, D: 60 })}
`

export default PageNotFound
