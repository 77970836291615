import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import moment from 'moment'

import image from '../../images/blog.jpg'

const BlogItem = (props) => {
  return (
    <Style onClick={props.onClick}>
      <img src={props.image} alt={props.title} />
      <h3>{props.title}</h3>
      <div className='blog-date'>{moment(props.date).format('DD MMMM YYYY')}</div>
      <div className='blog-desc'>{props.desc}</div>
    </Style>
  )
}

BlogItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

BlogItem.defaultProps = {
  image: image,
  title: 'the villa overlooks dramatic mountainous scenery',
  desc:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidis idunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ercaus itation ullamco laboris nisi ut aliquip',
  date: '12 Dec 2022',
  onClick: () => null
}

export default BlogItem

const Style = styled.div`
  label: BlogItem;

  cursor: pointer;
  font-family: 'SukhumvitSet';
  margin-bottom: 60px;

  > h3 {
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
  }

  > .blog-date {
    margin: 10px 0;
    font-size: 10px;
    letter-spacing: 3.6px;
    color: #888888;
  }

  > .blog-desc {
    color: #555555;
  }
`
