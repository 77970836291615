import { createModule } from 'redux-modux'

const initialState = {
  showPopup: true,
  content: null,
  products: [],
  productCategories: [],
  selectedCategory: null,
  selectedGender: null,
  blogs: [],
  tags: []
}

const handlers = {}

export default createModule({ moduleName: 'data', initialState, handlers })
