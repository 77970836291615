import { createActionWithFetching } from '../utils'
import {
  fetchBlogs,
  fetchContent,
  fetchProductCategory,
  fetchProducts,
  fetchTags
} from '../api/fetchers'
import dataModule from '../modules/data'

const getContent = () => {
  const callAction = async (dispatch, getState) => {
    const [content, products, productCategories, tags, blogs] = await Promise.all([
      fetchContent(),
      fetchProducts({
        per_page: 100
      }),
      fetchProductCategory({
        per_page: 100
      }),
      fetchTags({
        per_page: 100
      }),
      fetchBlogs({
        per_page: 100
      })
    ])

    const totalProductPages = Number(products.headers['x-wp-totalpages'])

    const getProductFetchers = () => {
      const fetchers = []

      if (totalProductPages > 1) {
        for (let index = 2; index <= totalProductPages; index++) {
          fetchers.push(fetchProducts({ per_page: 100, page: index }))
        }
      }

      return fetchers
    }

    dispatch(
      dataModule.set({
        key: 'content',
        value: content.data.acf
      })
    )

    dispatch(
      dataModule.set({
        key: 'products',
        value: products.data
      })
    )

    dispatch(
      dataModule.set({
        key: 'productCategories',
        value: productCategories.data
      })
    )

    dispatch(
      dataModule.set({
        key: 'tags',
        value: tags.data
      })
    )

    dispatch(
      dataModule.set({
        key: 'blogs',
        value: blogs.data
      })
    )

    if (totalProductPages > 1) {
      setTimeout(async () => {
        const moreProductResponse = await Promise.all(getProductFetchers())
        const moreProducts = moreProductResponse.reduce((result, item) => {
          return [...result, ...item.data]
        }, [])

        const currentProducts = getState().data.products

        dispatch(
          dataModule.set({
            key: 'products',
            value: [...currentProducts, ...moreProducts]
          })
        )
      }, 1500)
    }
  }

  return createActionWithFetching({
    callAction
  })
}

const getAllProducts = () => {
  const callAction = async (dispatch, getState) => {
    const [products, productCategories] = await Promise.all([
      fetchProducts({
        per_page: 100
      }),
      fetchProductCategory({
        per_page: 100
      })
    ])

    const totalProductPages = Number(products.headers['x-wp-totalpages'])

    const getProductFetchers = () => {
      const fetchers = []

      if (totalProductPages > 1) {
        for (let index = 2; index <= totalProductPages; index++) {
          fetchers.push(fetchProducts({ per_page: 100, page: index }))
        }
      }

      return fetchers
    }

    dispatch(
      dataModule.set({
        key: 'products',
        value: products.data
      })
    )

    dispatch(
      dataModule.set({
        key: 'productCategories',
        value: productCategories.data
      })
    )

    if (totalProductPages > 1) {
      setTimeout(async () => {
        const moreProductResponse = await Promise.all(getProductFetchers())
        const moreProducts = moreProductResponse.reduce((result, item) => {
          return [...result, ...item.data]
        }, [])

        const currentProducts = getState().data.products

        dispatch(
          dataModule.set({
            key: 'products',
            value: [...currentProducts, ...moreProducts]
          })
        )
      }, 1500)
    }
  }

  return createActionWithFetching({
    callAction
  })
}

const getAllBlogs = () => {
  const callAction = async (dispatch, getState) => {
    const blogs = await fetchBlogs({
      per_page: 100
    })

    dispatch(
      dataModule.set({
        key: 'blogs',
        value: blogs.data
      })
    )
  }

  return createActionWithFetching({
    callAction
  })
}

export { getContent, getAllProducts, getAllBlogs }
