import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import styled from '@emotion/styled'
import { Element } from 'react-scroll'
import { gsap } from 'gsap'
import isEmpty from 'lodash/isEmpty'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { breakpoint, fluidSizing } from '../../utils'

import arrowPrev from '../../images/arrow-prev.svg'
import arrowNext from '../../images/arrow-next.svg'
import banner1 from '../../images/banner-1.jpg'
import banner2 from '../../images/banner-2.jpg'
import banner3 from '../../images/banner-3.jpg'

const HomeBannerSlider = (props) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const sliderRef = useRef()
  const ref = useRef()

  useEffect(() => {
    const element = ref.current
    gsap.fromTo(
      element,
      {
        opacity: 0
      },
      {
        opacity: 1,
        duration: 2,
        delay: 0.3
      }
    )
  }, [])

  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    autoplay: true,
    infinite: true,
    pauseOnHover: false,
    speed: 1000,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveIndex(next)
  }

  const handleClickLink = () => {
    const url = props.items[activeIndex].link
    if (isEmpty(url)) return
    window.open(url)
  }

  const sliders = [...props.items]

  return (
    <Element name='home'>
      <Style ref={ref}>
        <Slider ref={sliderRef} {...settings}>
          {sliders.map((item, i) => (
            <div key={i}>
              <div className='banner-item' onClick={handleClickLink}>
                <div className='bg' style={{ backgroundImage: `url(${item.image})` }}></div>
                <div className={`banner-caption ${item.align}`} style={{ color: item.textColor }}>
                  <div className='caption'>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className='box-arrow'>
          <div className='prev' onClick={() => sliderRef.current.slickPrev()}>
            <img src={arrowPrev} alt='' />
          </div>
          <div className='next' onClick={() => sliderRef.current.slickNext()}>
            <img src={arrowNext} alt='' />
          </div>
        </div>
        <div className='icon-scroll'></div>
      </Style>
    </Element>
  )
}

HomeBannerSlider.propTypes = {
  items: PropTypes.array.isRequired
}

HomeBannerSlider.defaultProps = {
  items: [
    {
      image: banner1,
      align: 'right',
      title: 'The Watch Everyone Desires!',
      link: '',
      description:
        'The best in class elegant watches from the luxury brand Swiss Eagle high-quality watches into which a lot of care has gone in.',
      textColor: '#fff'
    },
    {
      image: banner2,
      align: 'left',
      title: 'The Watch Everyone Desires!',
      link: '',
      description:
        'The best in class elegant watches from the luxury brand Swiss Eagle high-quality watches into which a lot of care has gone in.',
      textColor: '#fff'
    },
    {
      image: banner3,
      align: 'left',
      title: 'The Watch Everyone Desires!',
      link: '',
      description:
        'The best in class elegant watches from the luxury brand Swiss Eagle high-quality watches into which a lot of care has gone in.',
      textColor: '#522A05'
    }
  ]
}

export default HomeBannerSlider

const Style = styled.div`
  label: HomeBannerSlider;

  position: relative;

  .banner-item,
  .slick-slide {
    position: relative;
    height: 100vh;
    cursor: pointer;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
    /* transform: scale(1.1) translateZ(0); */
  }

  /* .slider-track {
    transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
  } */

  .slick-active {
    .bg {
      /* opacity: 1; */
      /* transform: scale(1) translateZ(0); */
      animation: zoomOut 7s 0s linear;
    }

    h2 {
      animation: cssAnimation 6s 0.5s ease-in-out forwards;
    }

    p {
      animation: cssAnimation 6s 0.7s ease-in-out forwards;
    }
  }

  .banner-caption {
    display: flex;
    align-items: center;
    position: absolute;
    right: 50%;
    left: 0;
    top: 0;
    bottom: 0;
    padding-right: 80px;
    padding-left: 120px;

    &.right {
      left: 50%;
      right: 0;
      padding-left: 80px;
      padding-right: 120px;
    }

    h2 {
      opacity: 0;
      line-height: 1.2;
      margin-bottom: 20px;
      transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);

      ${fluidSizing('font-size', { SM: 10, M: 30, T: 45, D: 60 })}
    }

    p {
      font-family: 'SukhumvitSet';
      font-weight: bold;
      text-transform: uppercase;
      opacity: 0;
      line-height: 2;
      transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);

      ${fluidSizing('font-size', { SM: 3, T: 10, D: 12 })}
    }
  }

  .box-arrow {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;

    > .next,
    .prev {
      padding: 28px 27.5px;
      cursor: pointer;
      transform: scale(1);
      transition: all 0.3s ease-in-out;

      &:active {
        transform: scale(0.8);
      }
    }

    img {
      width: 25px;
      display: block;
    }
  }

  .icon-scroll,
  .icon-scroll:before {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
  .icon-scroll {
    width: 18px;
    height: 32px;
    border: 1px solid #fff;
    border-radius: 25px;
  }

  .icon-scroll:before {
    content: '';
    top: 4px;
    width: 4px;
    height: 4px;
    background: #fff;
    margin-left: -2px;
    border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
  }

  @keyframes scroll {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  @keyframes cssAnimation {
    0% {
      opacity: 0;
      transform: translate3d(0, 40px, 0);
    }
    20%,
    95% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    100% {
      opacity: 0;
      transform: translate3d(0, -40px, 0);
    }
  }

  @keyframes zoomOut {
    0% {
      opacity: 0;
      transform: scale(1.1) translateZ(0);
    }
    95% {
      opacity: 1;
      transform: scale(1) translateZ(0);
    }

    100% {
      opacity: 0;
    }
  }

  ${breakpoint('560px')} {
    margin-top: 70px;

    .banner-item,
    .slick-slide {
      height: 300px;
    }
    .banner-caption {
      padding: 0 30px !important;

      h2 {
        margin-bottom: 10px;
      }
    }
    .icon-scroll,
    .box-arrow {
      display: none;
    }
  }

  ${breakpoint('400px')} {
    .banner-item,
    .slick-slide {
      height: 220px;
    }
  }
`
