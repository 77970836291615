const getProductShortDescription = (product, forMetaTag) => {
  const datas = forMetaTag
    ? [`${product.acf.brand.name} ${product.acf.model.name}`]
    : [`<b>${product.acf.brand.name} ${product.acf.model.name}</b><br />`]

  if (product.acf.model_number) datas.push(`(${product.acf.model_number})`)
  if (product.acf.year) datas.push(`ปี ${product.acf.year}`)
  if (product.acf.item_condition) datas.push(`สภาพสินค้า ${product.acf.item_condition}`)

  return datas.join(' ')
}

export default getProductShortDescription
