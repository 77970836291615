import { Global, css } from '@emotion/react'
import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { setConfig } from 'react-hot-loader'
import { useEffect } from 'react'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'glud-component/build/style.css'
import './fonts/font.css'

import { history } from './utils'
import configureStore from './store/configureStore'
import Routes from './routes'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'
import { getContent } from './actions/action'

setConfig({
  pureSFC: true,
  reloadHooks: true
})

const store = configureStore(window.__INITIAL_STATE__)

const globalStyles = css`
  html,
  body {
    font-family: 'Geometos';
    background: #000;
    color: #fff;
    overflow-x: hidden;
    width: 100%;

    &.fixed {
      /* overflow: hidden !important; */
    }
  }

  .toastify {
    font-family: 'SukhumvitSet';
    &.is-success,
    &.is-danger {
      color: #000;
    }
  }

  .react-parallax > img {
    max-width: inherit;
  }

  .slick-slide,
  .slick-slide * {
    outline: none !important;
  }
`

function Root() {
  useEffect(() => {
    store.dispatch(getContent())
  }, [])

  return (
    <Provider store={store}>
      <Global styles={globalStyles} />
      <Router history={history}>
        <ScrollToTop />
        <Navigation />
        <Routes />
        <Footer />
      </Router>
    </Provider>
  )
}

if (module.hot) {
  module.hot.dispose(() => (window.__INITIAL_STATE__ = store.getState()))
}

export default process.env.NODE_ENV === 'development' ? hot(Root) : Root
