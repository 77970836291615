import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { fluidSizing } from '../../utils'

const TitleSection = (props) => {
  return (
    <Style align={props.align} light={props.light}>
      <h4>{props.label}</h4>
      <h1 dangerouslySetInnerHTML={{ __html: props.title }} />
    </Style>
  )
}

TitleSection.propTypes = {
  light: PropTypes.bool,
  align: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

TitleSection.defaultProps = {
  align: '',
  lable: 'Label',
  title: 'Title'
}

export default TitleSection

const Style = styled.div`
  label: TitleSection;

  text-align: ${(props) => props.align};
  color: ${(props) => (props.light ? '#fff' : '#000')};

  h4 {
    font-size: 8px;
    letter-spacing: 8px;
  }

  h1 {
    margin-top: 10px;
    line-height: 1.2;
    ${fluidSizing('font-size', { SM: 22, M: 22, T: 30, D: 35 })}
  }
`
