import Styled from '@emotion/styled'

import { breakpoint } from '../../utils'

const BorderAnimation = () => {
  return (
    <Style>
      <div className='item-1'></div>
      <div className='item-2'></div>
      <div className='item-3'></div>
      <div className='item-4'></div>
    </Style>
  )
}

const Style = Styled('div')`
  label: BorderAnimation;

  > div {
    position: absolute;
    z-index: 2;
  }

  > .item-1 {
    top: 100px;
    left: 0;
    right: 0;
    height: 1px;
    background: rgb(244 242 233 / 20%);
    transform: scaleX(0);
    transform-origin: right top;
    animation: animateX 1s 1s ease-in-out forwards;
  }

  > .item-2 {
    top: 0;
    bottom: 81px;
    left: 80px;
    width: 1px;
    background: rgb(244 242 233 / 20%);
    transform: scaleY(0);
    transform-origin: left bottom;
    animation: animateY 0.5s 1.7s ease-in-out forwards;
  }

  > .item-3 {
    top: 0;
    bottom: 81px;
    right: 80px;
    width: 1px;
    background: rgb(244 242 233 / 20%);
    transform: scaleY(0);
    transform-origin: right bottom;
    animation: animateY 1.5s 0.5s ease-in-out forwards;
  }

  > .item-4 {
    bottom: 80px;
    left: 0;
    right: 0;
    height: 1px;
    background: rgb(244 242 233 / 20%);
    transform-origin: right top;
    animation: animateX 2s 0s ease-in-out forwards;
  }

  @keyframes animateX {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }

  @keyframes animateY {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }

  ${breakpoint('1000px')} {
    > .item-1 {
      top: 70px;
    }
  }

  ${breakpoint('560px')} {
    display: none;
  }

`

export default BorderAnimation
