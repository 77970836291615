import Styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { getAllBlogs } from '../actions/action'
import BannerPage from '../components/BannerPage'
import PageContainer from '../components/PageContainer'
import Breadcrumb from '../components/Breadcrumb'
import BlogItem from '../components/BlogItem'
import LoadMore from '../components/LoadMore'
import { history } from '../utils'

const initialPerPage = 6

const Blog = () => {
  const dispatch = useDispatch()
  const appData = useSelector((state) => state.data)
  const [perPage, setPerPage] = useState(initialPerPage)
  const { tagId } = useParams()

  useEffect(() => {
    dispatch(getAllBlogs())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isEmpty(appData.blogs)) return null

  const { content, blogs } = appData

  const getBlogWithTag = () => {
    if (!tagId) return blogs

    const withTag = (blog) => blog.tags.some((t) => t === Number(tagId))
    return blogs.filter(withTag)
  }

  const blogWithTags = getBlogWithTag()

  const getDataListPerPage = () => {
    return blogWithTags.filter((_, i) => i < perPage)
  }

  const dataListPerPage = getDataListPerPage()

  const showLoadMore = () => {
    if (blogWithTags.length < 6) return false
    if (blogWithTags.length === dataListPerPage.length) return false
    return true
  }

  const isShowLoadMore = showLoadMore()

  return (
    <>
      <Helmet>
        <title>{content.meta_title_blog}</title>
        <meta name='title' content={content.meta_title_blog} />
        <meta name='description' content={content.meta_description_blog} />

        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://www.onmywatch-shop.com/blog' />
        <meta property='og:title' content={content.meta_title_blog} />
        <meta property='og:description' content={content.meta_description_blog} />
        <meta property='og:image' content='https://www.onmywatch-shop.com/on-my-watch.png' />
        <meta property='og:image:type' content='image/*' />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://www.onmywatch-shop.com/blog' />
        <meta property='twitter:title' content={content.meta_title_blog} />
        <meta property='twitter:description' content={content.meta_description_blog} />
        <meta
          property='twitter:image'
          content='https://www.onmywatch-shop.com/on-my-watch.png'
        ></meta>
      </Helmet>
      <BannerPage
        image={content.top_banner_blog}
        name='From the blog'
        title={content.banner_title_blog}
      />
      {blogWithTags.map((item, i) => (
        <Link key={i} to={`/blog/${item.id}`} />
      ))}
      <PageContainer>
        <Style>
          <Breadcrumb items={[{ label: 'บทความ' }]} />
          <div className='row'>
            {dataListPerPage.map((item, i) => (
              <div key={i} className='D-6 SM-12'>
                <BlogItem
                  title={item.title.rendered}
                  desc={item.acf.short_description}
                  date={item.date}
                  image={item.acf.featured_image}
                  onClick={() => history.push(`/blog/${item.id}/?title=${item.title.rendered}`)}
                />
              </div>
            ))}
          </div>
          <br />
          <div className='_center'>
            {isShowLoadMore && <LoadMore onClick={() => setPerPage(perPage + initialPerPage)} />}
          </div>
        </Style>
      </PageContainer>
    </>
  )
}

const Style = Styled('div')`
  label: Blog;

`

export default Blog
