import Styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import TitleSection from '../components/TitleSection'
import BannerPage from '../components/BannerPage'
import Breadcrumb from '../components/Breadcrumb'
import PageContainer from '../components/PageContainer'

const AboutUs = () => {
  const appData = useSelector((state) => state.data)

  if (isEmpty(appData.content)) return null

  const { content } = appData

  return (
    <>
      <Helmet>
        <title>{content.meta_title_about}</title>
        <meta name='title' content={content.meta_title_about} />
        <meta name='description' content={content.meta_description_about} />

        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://www.onmywatch-shop.com/about-us' />
        <meta property='og:title' content={content.meta_title_about} />
        <meta property='og:description' content={content.meta_description_about} />
        <meta property='og:image' content='https://www.onmywatch-shop.com/on-my-watch.png' />
        <meta property='og:image:type' content='image/*' />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://www.onmywatch-shop.com/about-us' />
        <meta property='twitter:title' content={content.meta_title_about} />
        <meta property='twitter:description' content={content.meta_description_about} />
        <meta
          property='twitter:image'
          content='https://www.onmywatch-shop.com/on-my-watch.png'
        ></meta>
      </Helmet>
      <BannerPage
        image={content.top_banner_about_us}
        name='About Us'
        title={content.banner_title_about}
      />

      <PageContainer>
        <Style>
          <Breadcrumb items={[{ label: 'เกี่ยวกับเรา' }]} />
          <div className='row columns-between'>
            <div className='D-5 M-6 SM-12'>
              <img src={content.image_about_us} width='100%' alt='' />
              <br />
              <br />
            </div>
            <div className='D-6 SM-12'>
              <TitleSection label='About Us' title='On my watch' />
              <br />
              <div
                className='content'
                dangerouslySetInnerHTML={{ __html: content.content_about_us }}
              />
            </div>
          </div>
        </Style>
      </PageContainer>
    </>
  )
}

const Style = Styled('div')`
  label: AboutUs;

  .content {
    font-family: 'SukhumvitSet';

    b {
      font-weight: bold;
    }
  }
`

export default AboutUs
