import { useRef } from 'react'
import Styled from '@emotion/styled'
import { Element } from 'react-scroll'
import Slider from 'react-slick'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { breakpoint, getProductShortDescription, history } from '../../utils'
import TitleSection from '../../components/TitleSection'
import ReadMore from '../../components/ReadMore'
import ProductItem from '../../components/ProductItem'
import prev from '../../images/prev.svg'
import next from '../../images/next.svg'
import shadowLeft from '../../images/shadow-left.png'
import shadowRight from '../../images/shadow-right.png'

const settings = {
  dots: false,
  arrows: false,
  fade: false,
  autoplay: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 4000,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true
      }
    }
  ]
}

const NewProduct = ({ items, newArrivals }) => {
  const sliderRef = useRef()
  if (isEmpty(items)) return null
  return (
    <Element name='new-product'>
      <Style>
        <div className='box-head'>
          <TitleSection align='center' label='Our Products' title='New Arrivals' />
        </div>
        <div className='box-body'>
          <div className='product-slider'>
            <div className='arrow-prev' onClick={() => sliderRef.current.slickPrev()}>
              <img src={prev} alt='' />
            </div>
            <div className='arrow-next' onClick={() => sliderRef.current.slickNext()}>
              <img src={next} alt='' />
            </div>

            <Slider ref={sliderRef} {...settings}>
              {newArrivals.map((item, i) => (
                <div key={item.id} className='item'>
                  <ProductItem
                    hot={item.acf.hot === 'yes'}
                    image={get(item, 'acf.product_images[0]')}
                    detail={getProductShortDescription(item)}
                    price={item.acf.price}
                    specialPrice={item.acf.special_price}
                    onClick={() => history.push(`products/${item.id}?title=${item.title.rendered}`)}
                  />
                </div>
              ))}
              {items.map((item, i) => (
                <div key={item.id} className='item'>
                  <ProductItem
                    hot={item.acf.hot === 'yes'}
                    image={get(item, 'acf.product_images[0]')}
                    detail={getProductShortDescription(item)}
                    price={item.acf.price}
                    specialPrice={item.acf.special_price}
                    onClick={() => history.push(`products/${item.id}?title=${item.title.rendered}`)}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <br />
          <br />
          <ReadMore label='See All' to='/products' />
        </div>
      </Style>
    </Element>
  )
}

const Style = Styled('div')`
  label: NewProduct;

  background: #fff;
  position: relative;
  padding-bottom: 60px;
  

  > .box-head {
    width: 1080px;
    height: 160px;
    padding: 60px;
    position: absolute;
    top: -160px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
  }

  > .box-body {
    position: relative;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    background: #fff;
    padding: 40px 90px;
    text-align: center;
    box-shadow: 0 20px 20px -15px #e9e9e9;

    &:before {
      content: '';
      width: 90px;
      height: 337px;
      background: #fff url(${shadowLeft}) center center no-repeat;
      position: absolute;
      left: -90px;
      bottom: 0;
    }

    &:after {
      content: '';
      width: 90px;
      height: 337px;
      background: #fff url(${shadowRight}) center center no-repeat;
      position: absolute;
      right: -90px;
      bottom: 0;
    }
  }

  .product-slider {
    position: relative;

    .item {
      padding: 10px;
    }
    
    .arrow-prev, .arrow-next {
      position: absolute;
      cursor: pointer;
    }

    .arrow-prev {
      top: 30%;
      left: -20px;
    }

    .arrow-next {
      top: 30%;
      right: -20px;
    }
  }

  ${breakpoint('1000px')} {
    > .box-head {
      width: 100%;
      left: 0;
      top: 0;
      transform: translateX(0%);
      position: relative;
    }

    > .box-body {
      padding: 0 20px 60px;
    }
    .arrow-prev, .arrow-next {
      display: none;
    }
  }

  ${breakpoint('560px')} {
    
  }

`

export default NewProduct
