import { useState, useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useChain, useTrail, useTransition, animated } from 'react-spring'
import styled from '@emotion/styled'

import logo from '../../images/logo.svg'
import { history, breakpoint, fluidSizing } from '../../utils'

const menus = [
  {
    to: '/about-us',
    label: 'เกี่ยวกับเรา'
  },
  {
    to: '/products',
    label: 'สินค้า'
  },
  {
    to: '/รับซื้อนาฬิกา',
    label: 'รับซื้อนาฬิกา'
  },
  {
    to: '/warranty',
    label: 'การรับประกัน'
  },
  {
    to: '/blog',
    label: 'บทความ'
  },
  {
    to: '/contact-us',
    label: 'ติดต่อเรา'
  }
]

const Navigation = (props) => {
  const [showNav, setShowNav] = useState(false)

  useEffect(() => {
    if (showNav) {
      document.body.style.position = 'fixed'
      document.body.style.top = `-${window.scrollY}px`
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [showNav])

  const menuRef = useRef()
  const menuItemRef = useRef()

  const trail = useTrail(menus.length, {
    ref: menuItemRef,
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: showNav ? 1 : 0
  })

  const menuTransition = useTransition(showNav, null, {
    ref: menuRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useChain(showNav ? [menuRef, menuItemRef] : [menuItemRef, menuRef], [0, 0.1])

  const handleToggleMenu = () => {
    setShowNav(!showNav)
  }
  return (
    <Style>
      <div className='logo' onClick={() => setShowNav(false)}>
        <img src={logo} alt='' onClick={() => history.push('/')} />
      </div>

      <div className='nav'>
        {menus.map((item, i) => (
          <div className='nav-item' key={i}>
            <NavLink to={item.to}>{item.label}</NavLink>
          </div>
        ))}
      </div>

      {menuTransition.map(
        (menu) =>
          menu.item && (
            <animated.div className='nav-mobile' style={menu.props}>
              {trail.map(({ x, height, ...rest }, index) => {
                const item = menus[index]
                return (
                  <animated.div
                    key={index}
                    className='nav-item'
                    style={rest}
                    onClick={handleToggleMenu}
                  >
                    <NavLink to={item.to}>{item.label}</NavLink>
                  </animated.div>
                )
              })}
            </animated.div>
          )
      )}

      <div className={`nav-mobile-toggle ${showNav && 'open'}`} onClick={handleToggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </Style>
  )
}

export default Navigation

const Style = styled.div`
  label: Navigation;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100px;
  padding: 10px 110px;
  background: rgb(0 0 0 / 60%);
  /* border-bottom: 1px solid rgb(244 242 233 / 20%); */

  > .logo {
    flex-shrink: 0;

    ${fluidSizing('width', { SM: 120, T: 160, D: 230 })}
    ${fluidSizing('height', { SM: 48, T: 48, D: 68 })}
    
    > img {
      width: 100%;
      display: block;
      cursor: pointer;
    }
  }

  > .nav-mobile {
    display: none;
  }
  > .nav,
  .nav-mobile {
    margin-left: auto;

    > .nav-item {
      display: inline-block;

      a {
        font-family: 'SukhumvitSet';
        font-weight: bold;
        color: #fff;
        display: block;
        padding: 10px 0;
        letter-spacing: 1.6px;
        transition: all 0.3s ease-in-out;

        &.active {
          color: #a98e55;
        }

        ${fluidSizing('font-size', { SM: 14, T: 12, D: 14 })}
        ${fluidSizing('margin-left', { SM: 10, T: 10, D: 30 })}
        ${fluidSizing('margin-right', { SM: 10, T: 10, D: 30 })}
      }
    }
  }

  > .nav-mobile-toggle {
    display: none;
    width: 30px;
    height: 25px;
    position: absolute;
    top: 22px;
    right: 25px;
    cursor: pointer;

    > span {
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      background: #fff;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: all 0.25s ease-in-out;
    }

    > span:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }

    > span:nth-child(2) {
      top: 50%;
      margin-top: -1px;
      transform-origin: left center;
    }

    > span:nth-child(3) {
      bottom: 0;
      transform-origin: left center;
    }

    &.open > span:nth-child(1) {
      transform: rotate(45deg);
      top: 1px;
      left: 4px;
    }

    &.open > span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    &.open > span:nth-child(3) {
      transform: rotate(-45deg);
      bottom: 2px;
      left: 4px;
    }
  }

  ${breakpoint('1000px')} {
    align-items: center;
    justify-content: center;
    height: 70px;

    > .nav {
      display: none;
    }

    > .nav-mobile {
      display: flex;
      position: fixed;
      background: rgb(0 0 0 / 60%);
      top: 70px;
      bottom: 0;
      left: 0;
      right: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .nav-item {
        margin: 15px 0;
      }
    }

    > .nav-mobile-toggle {
      display: inline-block;
    }
  }

  ${breakpoint('M')} {
    > .logo {
      margin-top: 10px;
    }
  }
`
