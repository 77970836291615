import Styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import Fuse from 'fuse.js'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import BannerPage from '../components/BannerPage'
import Breadcrumb from '../components/Breadcrumb'
import InputField from '../components/InputField'
// import LoadMore from '../components/LoadMore'
import PageContainer from '../components/PageContainer'
import ProductItem from '../components/ProductItem'
import SelectInput from '../components/SelectInput'
import { breakpoint, getProductShortDescription, history } from '../utils'
import { getAllProducts } from '../actions/action'
import dataModule from '../modules/data'
import LoadMore from '../components/LoadMore'

const initialPerPage = 9

const Product = () => {
  const dispatch = useDispatch()
  const { categorySlug } = useParams()

  const [perPage, setPerPage] = useState(initialPerPage)
  const [showFilter, setShowFilter] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [sorting, setSorting] = useState('date')
  const appData = useSelector((state) => state.data)

  useEffect(() => {
    dispatch(getAllProducts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isEmpty(appData.content)) return null

  const { productCategories, selectedGender, products, content } = appData

  const getCategory = () => {
    const result = productCategories.find((c) => c.slug === categorySlug)
    if (result) return result
    return null
  }

  const category = getCategory()
  const categoryId = get(category, 'id')

  const handleSelectCategory = (category) => {
    if (category === null) {
      history.push(`/products`)
      return
    }
    history.push(`/product-category/${category.slug}`)
  }

  const handleSelectGender = (gender) => {
    dispatch(
      dataModule.set({
        key: 'selectedGender',
        value: gender === selectedGender ? null : gender
      })
    )
  }

  const filterWithCategory = () => {
    const withCategory = (item) => item.product_category.some((c) => c === Number(categoryId))
    return categoryId ? products.filter(withCategory) : products
  }

  const productWithCategory = filterWithCategory()

  const filterProducts = () => {
    const filterWithGender = (item) => item.acf.gender === selectedGender
    // const filterWithCategory = (item) => item.product_category.some((c) => c === selectedCategory)
    const fromGender = selectedGender ? products.filter(filterWithGender) : productWithCategory
    // const fromCategory = selectedCategory ? fromGender.filter(filterWithCategory) : fromGender

    if (searchText === '') return fromGender

    const fuse = new Fuse(fromGender, {
      threshold: 0.2,
      keys: [
        'title.rendered',
        'acf.short_description',
        'acf.description',
        'acf.price',
        'acf.sale_price',
        'acf.gender',
        'acf.sizes',
        'acf.body',
        'acf.gender',
        'acf.model_number',
        'acf.strap',
        'acf.item_condition',
        'acf.strap_color',
        'acf.dial_color',
        'acf.clock_hands_color',
        'acf.power_reserve'
      ]
    })

    return fuse.search(searchText).map((data) => data.item)
  }

  const sortProducts = (datas) => {
    if (sorting === 'new') {
      const productNewArrivals = datas.filter((d) => d.acf.new_arrivals === 'yes')
      const productOrther = datas.filter((d) => d.acf.new_arrivals !== 'yes')
      return [...productNewArrivals, ...productOrther]
    }
    if (sorting === 'plth') return sortBy(datas, ['acf.price'])
    if (sorting === 'phtl') return sortBy(datas, ['acf.price']).reverse()
    return datas
  }

  const filteredProducts = filterProducts()
  const sortedProducts = sortProducts(filteredProducts)

  const getDataListPerPage = () => {
    return sortedProducts.filter((_, i) => i < perPage)
  }

  const dataListPerPage = getDataListPerPage()

  const showLoadMore = () => {
    if (sortedProducts.length < perPage) return false
    if (sortedProducts.length === dataListPerPage.length) return false
    return true
  }

  const isShowLoadMore = showLoadMore()

  const getMetaTag = () => {
    if (category)
      return {
        title: `ขายนาฬิกา ${category.name} มือสอง ของแท้ 100% ราคาดี พร้อม รับประกันหลังการขาย`,
        description: category.description
      }

    return {
      title: content.meta_title_products,
      description: content.meta_description_products
    }
  }

  const metaTag = getMetaTag()

  return (
    <>
      <Helmet>
        <title>{metaTag.title}</title>
        <meta name='title' content={metaTag.title} />
        <meta name='description' content={metaTag.description} />

        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://www.onmywatch-shop.com/products' />
        <meta property='og:title' content={metaTag.title} />
        <meta property='og:description' content={metaTag.description} />
        <meta property='og:image' content='https://www.onmywatch-shop.com/on-my-watch.png' />
        <meta property='og:image:type' content='image/*' />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://www.onmywatch-shop.com/products' />
        <meta property='twitter:title' content={metaTag.title} />
        <meta property='twitter:description' content={metaTag.description} />
        <meta
          property='twitter:image'
          content='https://www.onmywatch-shop.com/on-my-watch.png'
        ></meta>
      </Helmet>
      <BannerPage
        image={content.top_banner_products}
        name='Product'
        title={content.banner_title_products}
      />
      {products.map((item, i) => (
        <Link key={i} to={`/products/${item.id}`} />
      ))}
      <PageContainer>
        <Style>
          <Breadcrumb items={[{ label: 'สินค้า' }]} />
          <div className='row columns-between'>
            <div className='D-3 M-6'>
              <InputField
                placeholder='Search'
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
              />
            </div>
            <div className='D-3 M-6'>
              <SelectInput
                onChange={(e) => setSorting(e.target.value)}
                value={sorting}
                options={[
                  {
                    label: 'Sorting',
                    value: 'date'
                  },
                  {
                    label: 'New arrivals',
                    value: 'new'
                  },
                  {
                    label: 'Price: Low to high',
                    value: 'plth'
                  },
                  {
                    label: 'Price: High to low',
                    value: 'phtl'
                  }
                ]}
              />
            </div>
          </div>
          <div className='row box-products'>
            <div className='D-3 M-12'>
              <div className='toggle-filter' onClick={() => setShowFilter(!showFilter)}>
                Filter
              </div>
              <div className={`row filter-option ${showFilter && 'active'}`}>
                <div className='D-12 M-6 SM-12'>
                  <h3>Gender</h3>
                  <div className='gender-item' onClick={() => handleSelectGender('ladies')}>
                    <div className='name'>Ladies</div>
                    <div
                      className={`gender-check ${selectedGender === 'ladies' && 'active'}`}
                    ></div>
                  </div>
                  <div className='gender-item' onClick={() => handleSelectGender('men')}>
                    <div className='name'>Men's</div>
                    <div className={`gender-check ${selectedGender === 'men' && 'active'}`}></div>
                  </div>
                  <div className='gender-item' onClick={() => handleSelectGender('unisex')}>
                    <div className='name'>Unisex</div>
                    <div
                      className={`gender-check ${selectedGender === 'unisex' && 'active'}`}
                    ></div>
                  </div>
                  <br />
                  <br />
                </div>
                <div className='D-12 M-6 SM-12'>
                  <h3>PRODUCT CATEGORIES</h3>
                  <div
                    className={`category-item ${!categoryId && 'active'}`}
                    onClick={() => handleSelectCategory(null)}
                  >
                    <div className='name'>All</div>
                    <div className='cate-count'>{products.length}</div>
                  </div>
                  {productCategories
                    .filter((c) => c.parent === 0)
                    .map((item, i) => (
                      <Fragment key={i}>
                        <Link to={`/product-category/${item.slug}`} />
                        <div
                          className={`category-item ${Number(categoryId) === item.id && 'active'}`}
                          onClick={() => handleSelectCategory(item)}
                        >
                          <div className='name'>{item.name}</div>
                          <div className='cate-count'>{item.count}</div>
                        </div>
                      </Fragment>
                    ))}
                  <br />
                  <br />
                </div>
              </div>
            </div>

            <div className='D-9 M-12'>
              <div className='row'>
                {dataListPerPage.map((item, i) => (
                  <div className='D-4 T-6 M-4 SM-6' key={i}>
                    <div className='item'>
                      <ProductItem
                        hot={item.acf.hot === 'yes'}
                        image={get(item, 'acf.product_images[0]')}
                        detail={getProductShortDescription(item)}
                        price={item.acf.price}
                        specialPrice={item.acf.special_price}
                        onClick={() =>
                          history.push(`/products/${item.id}?title=${item.title.rendered}`)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
              <br />
              <div className='_center'>
                {isShowLoadMore && (
                  <LoadMore onClick={() => setPerPage(perPage + initialPerPage)} />
                )}
              </div>
            </div>
          </div>
        </Style>
      </PageContainer>
    </>
  )
}

const Style = Styled('div')`
  label: Product;

  .item {
    margin-bottom: 60px;
  }

  .category-item {
    font-family: 'SukhumvitSet';
    color: #555555;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 10px 0;

    &.active {
      color: #a98e55;
    }

    > .name {
      padding-right: 10px;
      line-height: 17px;
    }

    > .cate-count {

    }
  }

  .gender-item {
    font-family: 'SukhumvitSet';
    color: #555555;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 10px 0;

    > .gender-check {
      position: relative;
      width: 15px;
      height: 15px;
      border: solid 1px #e5e5e5;

      &.active:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        background: #a98e55;
      }
    }
  }

  .box-products {
    margin-top: 40px;
  }

  .toggle-filter {
    display: none;
    height: 45px;
    background: #000;
    color: #fff;
    text-align: center;
    padding: 15px;
    margin: 15px 0;
    cursor: pointer;
  }

  .filter-option {
    display: flex;
    &.active {
      display: flex;
    }
  }

  ${breakpoint('768px')} {
    .toggle-filter {
      display: block;
    }

    .box-products {
      margin-top: 0;
    }

    .filter-option {
      display: none;
    }
  }
`

export default Product
