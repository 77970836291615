import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

const BlogContentStyle = (props) => {
  return (
    <Style>
      <div dangerouslySetInnerHTML={{ __html: props.content }} />
    </Style>
  )
}

export default BlogContentStyle

const Style = styled.div`
  label: BlogContentStyle;
  font-family: 'SukhumvitSet';
  margin-top: 20px;

  img {
    display: block;

    &.aligncenter {
      margin: 0 auto;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  p {
    margin: 20px 0;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  a {
    color: #000;
  }
`
