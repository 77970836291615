import Styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import BannerPage from '../components/BannerPage'
import Breadcrumb from '../components/Breadcrumb'
// import LoadMore from '../components/LoadMore'
import PageContainer from '../components/PageContainer'
import { breakpoint, fluidSizing } from '../utils'

const BuyWatches = () => {
  const appData = useSelector((state) => state.data)

  if (isEmpty(appData.content)) return null

  const { content } = appData

  return (
    <>
      <Helmet>
        <title>{content.meta_title_buy_watches}</title>
        <meta name='title' content={content.meta_title_buy_watches} />
        <meta name='description' content={content.meta_description_buy_watches} />

        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://www.onmywatch-shop.com/buy-watches' />
        <meta property='og:title' content={content.meta_title_buy_watches} />
        <meta property='og:description' content={content.meta_description_buy_watches} />
        <meta property='og:image' content='https://www.onmywatch-shop.com/on-my-watch.png' />
        <meta property='og:image:type' content='image/*' />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://www.onmywatch-shop.com/buy-watches' />
        <meta property='twitter:title' content={content.meta_title_buy_watches} />
        <meta property='twitter:description' content={content.meta_description_buy_watches} />
        <meta
          property='twitter:image'
          content='https://www.onmywatch-shop.com/on-my-watch.png'
        ></meta>
      </Helmet>
      <BannerPage
        image={content.top_banner_buy_watches}
        name='Product'
        title={content.banner_title_buy_watches}
      />
      <PageContainer>
        <Style>
          <Breadcrumb items={[{ label: 'รับซื้อนาฬิกา' }]} />
          <div className='top'>
            <h1>{content.title_buy_watches}</h1>
            <p>{content.conent_buy_watches}</p>
          </div>

          {content.product_buy_wathces.map((item, i) => (
            <div key={i} className='product-buy-wathces'>
              <div className='image'>
                <img src={item.image} alt='' />
              </div>
              <div className='detail'>
                <div className='product-title'>{item.brand}</div>
                <div className='product-sub-title'>{item.short_description}</div>
                <p>{item.description}</p>
                <div className='line-split'></div>
                <div className='contact'>
                  <h3>Contact Us</h3>
                  <b>
                    PHONE NUMBER:{' '}
                    <a className='contact-item' href={`tel:${item.phone_number}`}>
                      {item.phone_number}
                    </a>
                  </b>
                  <br />
                  <b>
                    LINE ID:{' '}
                    <a href={item.line_url} target='_blank' className='contact-item'>
                      {item.line_id}
                    </a>
                  </b>
                </div>
              </div>
            </div>
          ))}

          {/* <br />
          <br />
          <div className='_center'>
            <LoadMore />
          </div> */}
        </Style>
      </PageContainer>
    </>
  )
}

const Style = Styled('div')`
  label: BuyWatches;

  .top {
    text-align: center;
    margin-bottom: 40px;
    font-family: 'SukhumvitSet';

    h1 {
      ${fluidSizing('font-size', { SM: 18, M: 20, T: 30, D: 30 })}
      margin-bottom: 10px;
      font-weight: bold;
    }
  }

  .line-split {
    height: 1px;
    margin: 25px 0;
    background-color: #f1f1f1;
  }

  .product-buy-wathces {
    font-family: 'SukhumvitSet';
    display: flex;
    margin-bottom: 40px;
    cursor: pointer;

    > .image {
      width: 360px;
      margin-right: 40px;
      flex-shrink: 0;

      img {
        display: block;
        border: 1px solid #e5e5e5;
      }
    }

    > .detail {
      .product-title {
        font-weight: bold;
        margin: 10px 0 20px;

        ${fluidSizing('font-size', { SM: 20, M: 20, T: 22, D: 22 })}
      }
      .product-sub-title {
        font-weight: bold;
        margin-bottom: 20px;
        /* line-height: 1; */

        ${fluidSizing('font-size', { SM: 14, M: 14, T: 16, D: 16 })}
      }
    }
  }

  .contact {
    > h3 {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .contact-item {
      color: #a98e55;
      font-size: 23px;
      text-decoration: none;
    }
  }

  ${breakpoint('M')} {
    .product-buy-wathces {
      > .image {
        width: 200px;
      }
    }
  }

  ${breakpoint('540px')} {
    .product-buy-wathces {
      display: block;

      > .image {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
`

export default BuyWatches
