import Styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import Breadcrumb from '../components/Breadcrumb'
import PageContainer from '../components/PageContainer'
import ProductItem from '../components/ProductItem'
import { getProductShortDescription, history } from '../utils'
import { Helmet } from 'react-helmet'

const options = {
  buttons: {
    backgroundColor: '#000',
    iconColor: '#fff'
  },
  thumbnails: {
    thumbnailsSize: ['90px', '120px'],
    thumbnailsOpacity: 0.4
  }
}

const Product = () => {
  const appData = useSelector((state) => state.data)
  const { products } = appData

  const { id } = useParams()

  const product = products.find((item) => item.id === Number(id))

  if (!product) return null

  const getPrice = () => {
    if (product.acf.special_price > 0) return product.acf.special_price
    return product.acf.price
  }

  const moreProducts = products.filter((item, i) => item.id !== Number(id))

  return (
    <>
      <Helmet>
        <title>{product.title.rendered}</title>
        <meta name='title' content={product.title.rendered} />
        <meta name='description' content={getProductShortDescription(product, true)} />

        <meta property='og:type' content='website' />
        <meta
          property='og:url'
          content={`https://www.onmywatch-shop.com/products/${id}?title=${product.title.rendered}`}
        />
        <meta property='og:title' content={product.title.rendered} />
        <meta property='og:description' content={getProductShortDescription(product, true)} />
        <meta property='og:image' content={product.acf.product_images[0]} />
        <meta property='og:image:type' content='image/*' />

        <meta property='twitter:card' content='summary_large_image' />
        <meta
          property='twitter:url'
          content={`https://www.onmywatch-shop.com/products/${id}?title=${product.title.rendered}`}
        />
        <meta property='twitter:title' content={product.title.rendered} />
        <meta property='twitter:description' content={getProductShortDescription(product, true)} />
        <meta property='twitter:image' content={product.acf.product_images[0]}></meta>
      </Helmet>
      <PageContainer noBanner>
        <Style>
          <Breadcrumb
            items={[{ label: 'สินค้า', to: '/products' }, { label: product.title.rendered }]}
          />
          {!product && <h3>Product is not found</h3>}
          {product && (
            <>
              <div className='row'>
                <div className='D-6 SM-12'>
                  <div className='box-product-image-detail'>
                    <SimpleReactLightbox>
                      <SRLWrapper options={options}>
                        <div className='row'>
                          {product.acf.product_images.map((image, i) => (
                            <div key={i} className={`${i === 0 ? 'D-12' : 'D-4'}`}>
                              <div className='thumbnail'>
                                <img src={image} alt='' />
                              </div>
                            </div>
                          ))}
                        </div>
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  </div>
                </div>

                <div className='D-6 SM-12'>
                  <h3>{product.title.rendered}</h3>
                  <p>
                    {/* <div
                      dangerouslySetInnerHTML={{ __html: getProductShortDescription(product) }}
                    ></div> */}
                    {product.acf.short_description}
                  </p>
                  <br />
                  {product.acf.special_price > 0 && (
                    <div className='old-price'>
                      <NumberFormat
                        value={product.acf.price}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </div>
                  )}
                  <div className='product-price'>
                    <NumberFormat
                      value={getPrice()}
                      displayType={'text'}
                      thousandSeparator={true}
                    />{' '}
                    THB
                  </div>
                  <div className='line-split'></div>
                  <h3>Products</h3>
                  <b>
                    PHONE NUMBER:{' '}
                    <a className='contact-item' href={`tel:${product.acf.phone_number}`}>
                      {product.acf.phone_number}
                    </a>
                  </b>
                  <br />
                  <b>
                    LINE ID:{' '}
                    <a href={product.acf.line_url} target='_blank' className='contact-item'>
                      {product.acf.line_id}
                    </a>
                  </b>
                  <div className='line-split'></div>
                  <h3>Description</h3>
                  <div dangerouslySetInnerHTML={{ __html: product.acf.description }} />
                  <div className='line-split'></div>
                  <div className='row'>
                    <div className='D-6 T-12'>
                      <div className='product-spec'>
                        <b>ยี่ห้อ:</b> <span>{product.acf.brand.name}</span>
                      </div>
                      <div className='product-spec'>
                        <b>รุ่น:</b> <span>{product.acf.model.name}</span>
                      </div>
                      <div className='product-spec'>
                        <b>หมายเลขรุ่น:</b> <span>{product.acf.model_number}</span>
                      </div>
                      <div className='product-spec'>
                        <b>ปี:</b> <span>{product.acf.year}</span>
                      </div>
                      <div className='product-spec'>
                        <b>สภาพสินค้า:</b> <span>{product.acf.item_condition}</span>
                      </div>
                      <div className='product-spec'>
                        <b>สำหรับเพศ:</b> <span>{product.acf.gender}</span>
                      </div>
                      <div className='product-spec'>
                        <b>ขนาด:</b> <span>{product.acf.sizes}</span>
                      </div>
                      <div className='product-spec'>
                        <b>สาย:</b> <span>{product.acf.strap}</span>
                      </div>
                      <div className='product-spec'>
                        <b>สีสาย:</b> <span>{product.acf.strap_color}</span>
                      </div>
                    </div>
                    <div className='D-6 T-12'>
                      <div className='product-spec'>
                        <b>สีหน้าปัด:</b> <span>{product.acf.dial_color}</span>
                      </div>
                      <div className='product-spec'>
                        <b>สีเข็ม:</b> <span>{product.acf.clock_hands_color}</span>
                      </div>
                      <div className='product-spec'>
                        <b>ปฏิทิน:</b> <span>{product.acf.calendar.label}</span>
                      </div>
                      <div className='product-spec'>
                        <b>จับเวลา:</b> <span>{product.acf.time.label}</span>
                      </div>
                      <div className='product-spec'>
                        <b>กล่อง:</b> <span>{product.acf.box.label}</span>
                      </div>
                      <div className='product-spec'>
                        <b>คู่มีือ:</b> <span>{product.acf.manual.label}</span>
                      </div>
                      <div className='product-spec'>
                        <b>เครื่อง:</b> <span>{product.acf.body}</span>
                      </div>
                      <div className='product-spec'>
                        <b>สำรองพลังงาน:</b> <span>{product.acf.power_reserve}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <br />
          <br />
          <br />
          <div className='title-more'>
            <h3>More choices:</h3>
          </div>
          <div className='row'>
            {moreProducts.map((item, i) => {
              if (i > 3) return null
              return (
                <div className='D-3 T-6 M-4 SM-6'>
                  <div className='item'>
                    <ProductItem
                      hot={item.acf.hot === 'yes'}
                      image={item.acf.product_images[0]}
                      detail={getProductShortDescription(item)}
                      price={item.acf.price}
                      specialPrice={item.acf.special_price}
                      onClick={() =>
                        history.push(`/products/${item.id}?title=${item.title.rendered}`)
                      }
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </Style>
      </PageContainer>
    </>
  )
}

const Style = Styled('div')`
  label: Product;
  
  font-family: 'SukhumvitSet';

  .title-more {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px;
    
    h3 {
      display: inline-block;
      background: #fff;
      padding-right: 10px;
      margin-bottom: 0;
      bottom: -8px;
      position: relative;
    }
  }

  h3 {
    font-family: 'Geometos';
    margin-bottom: 15px;
    font-size: 16px;
  }

  .old-price {
    margin-bottom: 10px;
    font-weight: bold;
    text-decoration: line-through;
  }

  .product-price {
    padding: 5px 30px;
    background-color: #a98e55;
    display: inline-block;
    font-size: 26px;
    font-weight: bold;
    color: #fff;
  }

  .line-split {
    height: 1px;
    margin: 25px 0;
    background-color: #f1f1f1;
  }

  .contact-item {
    font-size: 22px;
    text-decoration: none;
    color: #000;
  }

  .product-spec {
    display: flex;
    margin-bottom: 10px;

    > b {
      width: 100px;
      flex-shrink: 0;
    }
    > span {
      font-weight: normal;
      text-transform: capitalize;
    }
  }

  .box-product-image-detail {
    margin-bottom: 40px;

    img {
      display: block;
      border: 1px solid #e5e5e5;
    }

    .thumbnail {
      margin-bottom: 15px;
      cursor: pointer;
    }
  }

  .item {
    margin-bottom: 60px;
  }
`

export default Product
