import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { gsap } from 'gsap'

import aboutBanner from '../../images/about-banner.jpg'
import TitleSection from '../TitleSection'
import { breakpoint } from '../../utils'

const BannerPage = (props) => {
  const ref = useRef()

  useEffect(() => {
    const element = ref.current
    gsap.fromTo(
      element.querySelector('h4'),
      {
        opacity: 0,
        x: 100
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        delay: 0.2
      }
    )

    gsap.fromTo(
      element.querySelector('h1'),
      {
        opacity: 0,
        x: 100
      },
      {
        opacity: 1,
        x: 0,
        duration: 1
      }
    )
  }, [])

  return (
    <Style ref={ref} bg={props.image}>
      <div className='box-body'>
        <TitleSection light label={props.name} title={props.title} />
      </div>
    </Style>
  )
}

BannerPage.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
}

BannerPage.defaultProps = {
  name: 'About Us',
  title: 'We are experts in watches',
  image: aboutBanner
}

export default BannerPage

const Style = styled.div`
  label: BannerPage;

  height: 400px;
  background: #000 url(${(props) => props.bg}) center center no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  padding: 0 20px;

  .box-body {
    width: 870px;
    margin: 0 auto 60px;
    position: relative;
  }

  ${breakpoint('T')} {
    height: 280px;
    align-items: center;

    .box-body {
      margin: 70px auto 0;
      text-align: center;
    }
  }
`
