import { useRef, useEffect } from 'react'
import Styled from '@emotion/styled'
import { Element } from 'react-scroll'
import { gsap } from 'gsap'

import { breakpoint } from '../../utils'
import TitleSection from '../../components/TitleSection'
import ReadMore from '../../components/ReadMore'
import welcomImage from '../../images/welcome.jpg'

const Welcome = (props) => {
  const ref = useRef()

  useEffect(() => {
    const element = ref.current
    gsap.to(element.querySelector('.detail'), {
      opacity: 0
    })

    setTimeout(() => {
      gsap.to(element.querySelector('.detail'), {
        opacity: 1,
        duration: 1,
        delay: 0.3,
        scrollTrigger: {
          trigger: element,
          start: 'top bottom-=200',
          end: 'center center'
          // scrub: true,
          // markers: true
        }
      })

      gsap.fromTo(
        element.querySelector('.image'),
        {
          opacity: 0,
          y: 100
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: 0.5,
          scrollTrigger: {
            trigger: element,
            start: 'top bottom-=200',
            end: 'center center'
            // scrub: true,
            // markers: true
          }
        }
      )
    }, 300)
  }, [])

  return (
    <Element name='about-us'>
      <Style ref={ref}>
        <div className='box-detail'>
          <div className='detail'>
            <TitleSection align='right' label='About us' title='We are experts in watches' />
            <p dangerouslySetInnerHTML={{ __html: props.detail }} />
            <ReadMore line='left' label='Read More' to='/about-us' />
          </div>
        </div>
        <div className='image'></div>
      </Style>
    </Element>
  )
}

const Style = Styled('div')`
  label: Welcome;

  display: flex;
  color: #000;
  text-align: right;

  p {
    margin: 25px 0;
    font-size: 18px;
    color: #a3a3a3;
    line-height: 1.89;
    font-family: 'SukhumvitSet';
  }

  > .box-detail {
    width: 50%;
    background: #fff;
    text-align: right;

    > .detail {
      display: inline-block;
      width: 100%;
      max-width: 520px;
      padding: 120px 80px;
    }
  }

  > .image {
    width: 50%;
    background: #000 url(${welcomImage}) bottom center no-repeat;
    background-size: cover;
  }

  ${breakpoint('1000px')} {
    > .box-detail {
      width: 100%;

      > .detail {
        max-width: 100%;
      }
    }
    
    > .image {
      display: none;
    }
  }

  ${breakpoint('560px')} {
    p {
      font-size: 14px;
    }
    > .box-detail {

      > .detail {
        padding: 40px 20px;
      }
    }
  }

`

export default Welcome
