import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { Helmet } from 'react-helmet'

import { getContent } from '../../actions/action'
import HomePageNav from './HomePageNav'
import HomeBannerSlider from '../../components/HomeBannerSlider'
import BorderAnimation from './BorderAnimation'
import Welcome from './Welcome'
import Services from './Services'
import NewProduct from './NewProduct'
import Blog from './Blog'
import Popup from '../../components/Popup'

const Home = () => {
  const dispatch = useDispatch()
  const appData = useSelector((state) => state.data)

  useEffect(() => {
    dispatch(getContent())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isEmpty(appData.content)) return <Style />
  const { content, products, blogs } = appData
  const newArrivalsProducts = products.filter((item, i) => item.acf.new_arrivals === 'yes')
  const newProducts = products.filter((item, i) => i < 7 - newArrivalsProducts.length)

  return (
    <Style>
      <Helmet>
        <title>{content.meta_title_home}</title>
        <meta name='title' content={content.meta_title_home} />
        <meta name='description' content={content.meta_description_home} />

        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://www.onmywatch-shop.com/' />
        <meta property='og:title' content={content.meta_title_home} />
        <meta property='og:description' content={content.meta_description_home} />
        <meta property='og:image' content='https://www.onmywatch-shop.com/on-my-watch.png' />
        <meta property='og:image:type' content='image/*' />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://www.onmywatch-shop.com/' />
        <meta property='twitter:title' content={content.meta_title_home} />
        <meta property='twitter:description' content={content.meta_description_home} />
        <meta
          property='twitter:image'
          content='https://www.onmywatch-shop.com/on-my-watch.png'
        ></meta>
      </Helmet>
      <Popup />
      <HomePageNav />
      <BorderAnimation />
      <HomeBannerSlider items={content.home_banner_slider} />
      <Welcome detail={content.conent_about_us_for_home_page} />
      <Services
        icon1={content.warranty_1_image_white_color}
        icon2={content.warranty_2_image_white_color}
        icon3={content.warranty_3_image_white_color}
        icon4={content.warranty_4_image_white_color}
        title1={content.service1_title}
        title2={content.service2_title}
        title3={content.service3_title}
        title4={content.service4_title}
      />
      <NewProduct newArrivals={newArrivalsProducts} items={newProducts} />
      <Blog items={blogs} />
    </Style>
  )
}

const Style = styled('div')`
  min-height: 100vh;
`

export default Home
