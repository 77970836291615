import { useEffect } from 'react'
import styled from '@emotion/styled'
import { Link, scrollSpy } from 'react-scroll'

import { breakpoint } from '../../utils'

const duration = 500
const offset = 0

const HomePageNav = (props) => {
  useEffect(() => {
    setTimeout(() => {
      scrollSpy.update()
    }, 1000)
  }, [])

  return (
    <Style>
      <div className='home-navigation'>
        <Link activeClass='active' to='home' spy smooth duration={duration} offset={offset}>
          <div className='name'>Home</div>
        </Link>
        <Link activeClass='active' to='about-us' spy smooth duration={duration} offset={offset}>
          <div className='name'>About Us</div>
        </Link>
        <Link activeClass='active' to='warranty' spy smooth duration={duration} offset={offset}>
          <div className='name'>Warranty</div>
        </Link>
        <Link activeClass='active' to='new-product' spy smooth duration={duration} offset={offset}>
          <div className='name'>New Arrivals</div>
        </Link>
        <Link activeClass='active' to='blog' spy smooth duration={duration} offset={offset}>
          <div className='name'>Blog</div>
        </Link>
      </div>
    </Style>
  )
}

export default HomePageNav

const Style = styled.div`
  label: HomePageNav;

  position: fixed;
  z-index: 3;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);

  .home-navigation {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 20px;
      height: 20px;
      background: transparent;
      border: 1px solid transparent;
      border-radius: 100%;
      margin: 5px 0;
      transition: all 0.3s ease-in-out;

      &:after {
        display: inline-block;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #e0e0e0;
        transition: all 0.3s ease-in-out;
      }

      .name {
        position: absolute;
        opacity: 0;
        color: rgb(167 167 167 / 40%);
        font-size: 8px;
        line-height: 8px;
        right: 30px;
        top: 50%;
        width: 70px;
        text-align: right;
        transform: translateY(-50%);
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        border: 1px solid rgb(167 167 167 / 7%);
        &:after {
          /* background: #a98e55; */
        }

        .name {
          opacity: 1;
        }
      }

      &.active {
        border: 1px solid rgb(169 142 85 / 25%);
        &:after {
          background: #a98e55;
        }

        .name {
          color: #a98e55;
        }
      }
    }
  }

  ${breakpoint('1000px')} {
    display: none;
  }
`
