import Styled from '@emotion/styled'
import { Element } from 'react-scroll'

import { breakpoint, history } from '../../utils'
import TitleSection from '../../components/TitleSection'
import BlogItem from '../../components/BlogItem'

const Blog = ({ items }) => {
  return (
    <Element name='blog'>
      <Style>
        <TitleSection align='center' label='from the blog' title='Latest news' />

        <div className='blog-item-group'>
          <div className='row'>
            {items.map((item, i) => {
              if (i > 3) return null
              return (
                <div key={i} className='D-6 M-12'>
                  <div className='blog-item'>
                    <BlogItem
                      title={item.title.rendered}
                      desc={item.acf.short_description}
                      date={item.date}
                      image={item.acf.featured_image}
                      onClick={() => history.push(`/blog/${item.id}/?title=${item.title.rendered}`)}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Style>
    </Element>
  )
}

const Style = Styled('div')`
  label: Blog;

  background: #fff;
  color: #000;
  padding: 20px;

  .blog-item-group {
    width: 100%;
    max-width: 870px;
    margin: 40px auto 0;
    display: flex;
    flex-wrap: wrap;

    .blog-item {
      /* margin-bottom: 60px; */
    }
  }

  ${breakpoint('1000px')} {

  }

  ${breakpoint('560px')} {

  }

`

export default Blog
