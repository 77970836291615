import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const ReadMore = (props) => {
  return (
    <Style>
      {(props.line === 'left' || props.line === 'both') && <div className='line left'></div>}
      <Link to={props.to}>{props.label}</Link>
      {(props.line === 'right' || props.line === 'both') && <div className='line right'></div>}
    </Style>
  )
}

ReadMore.propTypes = {
  label: PropTypes.string.isRequired,
  line: PropTypes.string.isRequired
}

ReadMore.defaultProps = {
  to: '/',
  line: 'both',
  label: 'Read More'
}

export default ReadMore

const Style = styled.div`
  label: ReadMore;

  display: inline-flex;
  align-items: center;

  > .line {
    width: 50px;
    height: 3px;
    background: #000;
    margin: 0 10px;
    transform: scaleX(1);
    transition: all 0.3s ease-in-out;
    &.left {
      transform-origin: right center;
    }
    &.right {
      transform-origin: left center;
    }
  }

  &:hover {
    > .line {
      transform: scaleX(1.5);
    }
  }

  a {
    color: #000;
    font-size: 16px;
    line-height: 1;
  }
`
