import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import NumberFormat from 'react-number-format'

import product from '../../images/product.jpg'

const ProductItem = (props) => {
  return (
    <Style onClick={props.onClick}>
      <div className='img'>
        {props.hot && <div className='hot'>Hot item</div>}
        <img src={props.image} alt='' />
      </div>
      <div className='detail' dangerouslySetInnerHTML={{ __html: props.detail }}></div>
      <div className='price'>
        <NumberFormat value={props.price} displayType={'text'} thousandSeparator={true} /> THB
      </div>
    </Style>
  )
}

ProductItem.propTypes = {
  hot: PropTypes.bool,
  image: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

ProductItem.defaultProps = {
  image: product,
  detail:
    'ROLEX Datejust 31 White Dial Automatic Ladies Steel And 18kt Everose Gold Oyster Watch 278341WRO',
  price: 40000,
  onClick: () => null
}

export default ProductItem

const Style = styled.div`
  label: ProductItem;

  font-family: 'SukhumvitSet';
  font-style: normal;
  color: #010101;
  cursor: pointer;

  > .img {
    font-family: 'Geometos';
    position: relative;
    border: 1px solid #e5e5e5;

    img {
      width: 100%;
      display: block;
    }

    .hot {
      position: absolute;
      top: -1px;
      right: -1px;
      background: #a98e55;
      padding: 5px 15px;
      font-size: 10px;
      color: #fff;
    }
  }

  > .detail {
    text-align: center;
    padding: 20px;
    padding-bottom: 10px;
    font-size: 13px;
  }

  > .price {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
`
