import createFetcher from './createFetcher'

const host = process.env.REACT_APP_HOST || ''
const useMock = false

const fetchContent = (params) =>
  createFetcher({
    useMock,
    method: 'get',
    url: `${host}/wp-json/acf/v3/options/options`,
    params,
    jsonMock: 'content.json',
    delay: 0
  })

const fetchProducts = (params) =>
  createFetcher({
    useMock,
    method: 'get',
    url: `${host}/wp-json/wp/v2/products`,
    params,
    jsonMock: 'products.json',
    delay: 0
  })

const fetchProductCategory = (params) =>
  createFetcher({
    useMock,
    method: 'get',
    url: `${host}/wp-json/wp/v2/product_category`,
    params,
    jsonMock: 'category.json',
    delay: 0
  })

const fetchTags = (params) =>
  createFetcher({
    useMock,
    method: 'get',
    url: `${host}/wp-json/wp/v2/tags`,
    params,
    jsonMock: 'tags.json',
    delay: 0
  })

const fetchBlogs = (params) =>
  createFetcher({
    useMock,
    method: 'get',
    url: `${host}/wp-json/wp/v2/blog`,
    params,
    jsonMock: 'blog.json',
    delay: 0
  })

export { fetchContent, fetchProducts, fetchBlogs, fetchProductCategory, fetchTags }
