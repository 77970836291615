import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import { breakpoint, history } from '../../utils'

const Breadcrumb = (props) => {
  const handleClickBreadcrumb = (to) => {
    if (!to) return
    history.push(to)
  }
  return (
    <Style>
      <div className='breadcrumb-item' onClick={() => history.push('/')}>
        หน้าแรก
      </div>
      {props.items.map((item, i) => (
        <div key={i} className='breadcrumb-item' onClick={() => handleClickBreadcrumb(item.to)}>
          {item.label}
        </div>
      ))}
    </Style>
  )
}

Breadcrumb.propTypes = {
  items: PropTypes.array.isRequired
}

Breadcrumb.defaultProps = {
  items: [
    {
      label: 'Product',
      to: '/products'
    },
    {
      label: 'Apple Watch S6'
    }
  ]
}

export default Breadcrumb

const Style = styled.div`
  label: Breadcrumb;

  font-family: 'SukhumvitSet';
  font-weight: bold;
  margin-bottom: 40px;

  .breadcrumb-item {
    display: inline-block;
    font-size: 12px;
    cursor: pointer;

    &:not(:last-of-type):after {
      content: '>';
      display: inline-block;
      width: 40px;
      text-align: center;
    }

    &:last-of-type {
      cursor: inherit;
      color: #a98e55;
    }
  }

  ${breakpoint('M')} {
    margin-bottom: 20px;
  }
`
