import Styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'

import BannerPage from '../components/BannerPage'
import Breadcrumb from '../components/Breadcrumb'
import PageContainer from '../components/PageContainer'
import warrantyIcon from '../images/icon-warranty-black.svg'
import checkedIcon from '../images/icon-checked-black.svg'
import consignmentIcon from '../images/icon-consignment-black.svg'
import profressionalIcon from '../images/icon-profressional-black.svg'

import { breakpoint, fluidSizing } from '../utils'
import { Helmet } from 'react-helmet'

const Warranty = () => {
  const appData = useSelector((state) => state.data)

  if (isEmpty(appData.content)) return null

  const { content } = appData

  return (
    <>
      <Helmet>
        <title>{content.meta_title_warranty}</title>
        <meta name='title' content={content.meta_title_warranty} />
        <meta name='description' content={content.meta_description_warranty} />

        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://www.onmywatch-shop.com/warranty' />
        <meta property='og:title' content={content.meta_title_warranty} />
        <meta property='og:description' content={content.meta_description_warranty} />
        <meta property='og:image' content='https://www.onmywatch-shop.com/on-my-watch.png' />
        <meta property='og:image:type' content='image/*' />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://www.onmywatch-shop.com/warranty' />
        <meta property='twitter:title' content={content.meta_title_warranty} />
        <meta property='twitter:description' content={content.meta_description_warranty} />
        <meta
          property='twitter:image'
          content='https://www.onmywatch-shop.com/on-my-watch.png'
        ></meta>
      </Helmet>
      <BannerPage
        image={content.top_banner_warranty}
        name='Warranty'
        title={content.banner_title_warranty}
      />
      <Style>
        <PageContainer>
          <Breadcrumb items={[{ label: 'การรับประกัน' }]} />
          <div className='top'>
            <h1>{content.title_warranty}</h1>
            <p>{content.conent_warranty}</p>
          </div>
        </PageContainer>
        <PageContainer bgColor='#f9f9f9'>
          <div className='service-item'>
            <div className='item'>
              <div className='image'>
                <img src={content.warranty_1_image_black_color} alt='' />
              </div>
              <div className='detail'>
                <h3>{content.service1_title}</h3>
                <p>{content.service1}</p>
              </div>
            </div>
            <div className='item'>
              <div className='image'>
                <img src={content.warranty_2_image_black_color} alt='' />
              </div>
              <div className='detail'>
                <h3>{content.service2_title}</h3>
                <p>{content.service2}</p>
              </div>
            </div>
            <div className='item'>
              <div className='image'>
                <img src={content.warranty_3_image_black_color} alt='' />
              </div>
              <div className='detail'>
                <h3>{content.service3_title}</h3>
                <p>{content.service3}</p>
              </div>
            </div>
            <div className='item'>
              <div className='image'>
                <img src={content.warranty_4_image_black_color} alt='' />
              </div>
              <div className='detail'>
                <h3>{content.service4_title}</h3>
                <p>{content.service4}</p>
              </div>
            </div>
          </div>
        </PageContainer>
      </Style>
    </>
  )
}

const Style = Styled('div')`
  label: Warranty;

  .top {
    text-align: center;

    h1 {
      ${fluidSizing('font-size', { SM: 18, M: 20, T: 30, D: 30 })}
      margin-bottom: 10px;
    }
    p {
      font-family: 'SukhumvitSet';
    }
  }

  .service-item {
    display: flex;
    flex-wrap: wrap;

    > .item {
      width: 50%;
      padding: 30px;
      display: flex;
      align-items: center;
      font-family: 'SukhumvitSet';
      border: 1px solid #fff;

      > .image {
        display: flex;
        margin-right: 30px;
        width: 100px;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;

        > img {
          display: block;
          width: auto;
          height: 100px;
        }
      }

      > .detail {
        > h3 {
          font-family: 'Geometos';
          font-size: 17px;
          margin-bottom: 10px;
        }
      }

      &:nth-child(1) {
        padding-left: 0;
        border-left: none;
        border-top: none;
      }
      &:nth-child(2) {
        padding-right: 0;
        border-right: none;
        border-top: none;
      }
      &:nth-child(3) {
        padding-left: 0;
        border-left: none;
        border-bottom: none;
      }
      &:nth-child(4) {
        padding-right: 0;
        border-right: none;
        border-bottom: none;
      }
    }
  }

  ${breakpoint('1000px')} {
    .service-item {
      > .item {
        display: block;
        text-align: center;
        padding: 20px;

        > .image {
          margin: 0 auto 40px;
        }
      }
    }
  }

  ${breakpoint('540px')} {
    .service-item {
      display: block;

      > .item {
        width: 100%;
        padding: 20px !important;
        border: none;
      }
    }
  }
`

export default Warranty
