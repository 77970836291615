import Styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { breakpoint } from '../../utils'
import ChatSocialList from '../ChatSocialList'

const menus = [
  {
    to: '/',
    label: 'หน้าแรก'
  },
  {
    to: '/about-us',
    label: 'เกี่ยวกับเรา'
  },
  {
    to: '/products',
    label: 'สินค้า'
  },
  {
    to: '/รับซื้อนาฬิกา',
    label: 'รับซื้อนาฬิกา'
  },
  {
    to: '/warranty',
    label: 'การรับประกัน'
  },
  {
    to: '/blog',
    label: 'บทความ'
  },
  {
    to: '/policy',
    label: 'นโยบาย'
  },
  {
    to: '/contact-us',
    label: 'ติดต่อเรา'
  }
]

const Footer = () => {
  const appData = useSelector((state) => state.data)

  if (isEmpty(appData.content)) return null
  const { content } = appData
  return (
    <Style>
      <ChatSocialList facebookURL={content.facebook} lineURL={content.line_id} />
      <div className='box-body'>
        <div className='row'>
          <div className='D-5 T-7 M-6 SM-12'>
            <h3>Address</h3>
            <p>{content.address}</p>
            <br />
            <h3>Email</h3>
            <p>
              <a href={`mailto:${content.email}`} className='tel'>
                {content.email}
              </a>
            </p>
            <br />
            <h3>Phone number</h3>
            <p>
              <a href={`tel:${content.phone_number}`} className='tel'>
                {content.phone_number}
              </a>
            </p>
            <br />
            <h3>Line</h3>
            <a href={`${content.line_id}`} className='tel'>
              @Onmywatch
            </a>
            <br />
            <br />
          </div>
          <div className='D-2 T-3 SM-12'>
            <h3>Link</h3>
            <div className='footer-navigation'>
              {menus.map((item, i) => (
                <NavLink key={i} to={item.to} exact={i === 0}>
                  {item.label}
                </NavLink>
              ))}
            </div>
            <br />
            <br />
          </div>
          <div className='D-2 M-3 SM-12'>
            <h3>Social</h3>
            <div className='social-link'>
              <a href={content.facebook} target='_blank' className='fab fa-facebook-f'></a>
              <a href={content.instagram} target='_blank' className='fab fa-instagram'></a>
              <a href={content.twitter} target='_blank' className='fab fa-twitter'></a>
              <a href={content.youtube} target='_blank' className='fab fa-youtube'></a>
            </div>
            <br />
            <br />
          </div>
          <div className='D-3 T-12'>
            <h3>Copyright</h3>
            <p>© Copyright by On My Watch</p>
          </div>
        </div>
      </div>
    </Style>
  )
}

const Style = Styled('div')`
  label: Footer;

  background: #171717;
  color: #fff;
  padding: 60px 20px;
  font-family: 'SukhumvitSet';

  h3 {
    font-family: 'Geometos';
    margin-bottom: 5px;
  }

  .box-body {
    width: 100%;
    max-width: 870px;
    margin: 0 auto;
  }

  .tel {
    color: #fff;
    text-decoration: none;
  }

  .footer-navigation {
    a {
      display: block;
      color: #fff;
      margin-bottom: 8px;

      &.active {
        color: #a98e55;
      }
    }
  }

  .social-link {
    a {
      color: #fff;
      transition: all 0.3s ease-in-out;
      margin-right: 10px;

      &:hover {
        color: #a98e55;
      }
    }
  }

  ${breakpoint('1000px')} {

  }

  ${breakpoint('560px')} {
    text-align: center;

    .blog-item-group {
      .blog-item {
        width: 100%;
      }
    }
  }

`

export default Footer
