import React, { useState } from 'react'
import styled from '@emotion/styled'
import Input from 'glud-component/lib/Input'

const InputField = (props) => {
  const [touched, setTouched] = useState(false)

  return (
    <Style>
      <Input onlyContain {...props} onFocus={() => setTouched(true)} />
      {touched && props.isError && <div className='error-message'>{props.errorMessage}</div>}
    </Style>
  )
}

export default InputField

const Style = styled.div`
  label: InputField;

  .error-message {
    font-family: 'SukhumvitSet';
    margin-top: 5px;
    color: #a98e55;
  }

  .input {
    border-radius: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'SukhumvitSet';
    height: 45px;
    padding: 15px;
    outline: none;
    box-shadow: none;
    border-color: #ebebeb;
    transition: all 0.3s ease-in-out;

    &:focus {
      border-color: #a98e55;
    }
  }
`
