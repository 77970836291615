import { useEffect, useRef } from 'react'
import Styled from '@emotion/styled'
import { Element } from 'react-scroll'
import { Parallax } from 'react-parallax'
import { gsap } from 'gsap'

import { breakpoint, fluidSizing, history } from '../../utils'
// import TitleSection from '../../components/TitleSection'
// import ReadMore from '../../components/ReadMore'
import parallaxImage from '../../images/parallax.jpg'
import warrantyIcon from '../../images/icon-warranty.svg'
import checkedIcon from '../../images/icon-checked.svg'
import consignmentIcon from '../../images/icon-consignment.svg'
import profressionalIcon from '../../images/icon-profressional.svg'

const Services = (props) => {
  const ref = useRef()

  useEffect(() => {
    const element = ref.current

    setTimeout(() => {
      element.querySelectorAll('.item').forEach((item, i) => {
        gsap.fromTo(
          item,
          {
            opacity: 0,
            y: 20
          },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: i / 5,
            scrollTrigger: {
              trigger: element,
              start: 'top bottom-=200',
              end: 'center center'
              // scrub: true,
              // markers: true
            }
          }
        )
      })

      gsap.fromTo(
        element.querySelector('.title-group'),
        {
          opacity: 0
        },
        {
          opacity: 1,
          duration: 1,
          delay: 1,
          scrollTrigger: {
            trigger: element,
            start: 'top bottom-=200',
            end: 'center center'
            // scrub: true,
            // markers: true
          }
        }
      )
    }, 300)
  }, [])

  return (
    <Element name='warranty'>
      <Parallax bgImage={parallaxImage} strength={150}>
        <Style ref={ref}>
          <div className='box-line'>
            <div className='line-1'></div>
            <div className='line-2'></div>
            <div className='line-3'></div>
            <div className='line-4'></div>
          </div>
          <div className='service-item'>
            <div className='item'>
              <img src={props.icon1} alt='' />
              <div className='text'>{props.title1}</div>
            </div>
            <div className='item'>
              <img src={props.icon2} alt='' />
              <div className='text'>{props.title2}</div>
            </div>
            <div className='item'>
              <img src={props.icon3} alt='' />
              <div className='text'>{props.title3}</div>
            </div>
            <div className='item'>
              <img src={props.icon4} alt='' />
              <div className='text'>{props.title4}</div>
            </div>
          </div>
          <div className='title-group' onClick={() => history.push('/warranty')}>
            <h4>Warranty</h4>
            <h1>Service on my watch</h1>
          </div>
        </Style>
      </Parallax>
    </Element>
  )
}

const Style = Styled('div')`
  label: Services;

  ${fluidSizing('height', { SM: 500, M: 500, T: 700, D: 700 })}
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .service-item {
    display: flex;
    margin-top: 120px;

    > .item {
      ${fluidSizing('width', { SM: 30, M: 100, T: 160, D: 160 })}
      ${fluidSizing('margin', { SM: 5, M: 20, T: 30, D: 40 })}
      text-align: center;

      .text {
        ${fluidSizing('font-size', { SM: 6, M: 10, T: 14, D: 14 })}
      }

      img {
        ${fluidSizing('height', { SM: 30, M: 40, T: 60, D: 80 })}
        display: inline-block;
        margin-bottom: 20px;
      }
    }
  }

  .title-group {
    position: relative;
    z-index: 2;
    margin-top: 30px;
    text-align: center;
    cursor: pointer;
    
    > h4 {
      font-size: 8px;
      letter-spacing: 8px;
      margin-bottom: 10px;
    }

    > h1 {
      ${fluidSizing('font-size', { SM: 18, M: 30, T: 40, D: 54 })}
    }
  }

  > .box-line {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 80px;
    right: 80px;
    bottom: 0;
    display: flex;

    > div {
      width: 25%;
      height: 100%;
    }
    
    > .line-1 {
      border-left: 1px solid rgb(244 242 233 / 20%);
      border-right: 1px solid rgb(244 242 233 / 20%);
    }

    > .line-3 {
      border-left: 1px solid rgb(244 242 233 / 20%);
      border-right: 1px solid rgb(244 242 233 / 20%);
    }

    > .line-4 {
      border-right: 1px solid rgb(244 242 233 / 20%);
    }
  }

  ${breakpoint('1000px')} {
    justify-content: center;

    > .box-detail {
      width: 100%;

      > .detail {
        max-width: 100%;
      }
    }

    .service-item {
      margin-top: 0;
    }
    
    > .image {
      display: none;
    }
  }

  ${breakpoint('560px')} {
    > .box-line {
      left: 20px;
      right: 20px;
    }
    > .box-detail {

      > .detail {
        padding: 60px 40px;
      }
    }
  }

`

export default Services
