import React from 'react'
import styled from '@emotion/styled'
import Select from 'glud-component/lib/Select'

const SelectInput = (props) => {
  return (
    <Style>
      <Select onlyContain {...props} />
    </Style>
  )
}

export default SelectInput

const Style = styled.div`
  label: SelectInput;

  .field {
    /* padding: 15px; */
  }

  .select {
    height: 45px;
  }

  select {
    border-radius: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'SukhumvitSet';
    padding: 15px;
    outline: none;
    box-shadow: none;
    border-color: #ebebeb;
    transition: all 0.3s ease-in-out;
    height: 45px;

    &:focus {
      border-color: #a98e55;
      box-shadow: none;
    }
  }
`
