import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from '@emotion/styled'

const ChatSocialList = (props) => {
  const [show, setShow] = useState(false)

  const handleClick = (url) => {
    window.open(url)
  }

  return (
    <Style>
      <ul className={`chat-item-list ${show && 'active'}`}>
        <li className='chat-item' onClick={() => handleClick('http://m.me/onmywatch.studio')}>
          <div className='fab fa-facebook-messenger'></div>
        </li>
        <li className='chat-item' onClick={() => handleClick(props.lineURL)}>
          <div className='fab fa-line'></div>
        </li>
      </ul>
      <div className='toggle-btn' onClick={() => setShow(!show)}>
        <i className={`fas fa-${show ? 'times' : 'comments'}`}></i>
      </div>
    </Style>
  )
}

ChatSocialList.propTypes = {
  facebookURL: PropTypes.string.isRequired,
  lineURL: PropTypes.string.isRequired
}

export default ChatSocialList

const Style = styled.div`
  label: ChatSocialList;

  position: fixed;
  z-index: 3;
  right: 15px;
  bottom: 95px;
  width: 50px;

  .chat-item-list {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;

    .chat-item {
      opacity: 0;
    }
    &.active {
      .chat-item:nth-child(1) {
        transform: translate3d(0, -240%, 0);
        opacity: 1;
      }

      .chat-item:nth-child(2) {
        transform: translate3d(0, -120%, 0);
        opacity: 1;
      }
    }
  }

  .toggle-btn,
  .chat-item {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    width: 50px;
    height: 50px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: #aa8e55;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    transition: all 0.3s ease-in-out;
  }
`
